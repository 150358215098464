












































































































import Vue from 'vue'
import Component from 'vue-class-component'
import i18n from '@/i18n'

@Component({
  layout: 'blank',
})
export default class OrderPaymentNotification extends Vue {
  h = this.$route.query.h       // Hash
  s = this.$route.params.siteId // Shop ID
  o = this.$route.query.o       // Order ID
  lang = this.$route.query?.lang
    ? this.$route.query.lang.toString()
    : 'sv'

  data = {
    checkoutUrl: "",
    currency: "",
    custCarRegNo: "",
    date: "",
    logo: "",
    orderLines: [],
    shopName: "",
    template: {
      paymentTitle: "",
    },
    total: 0,
    rounding: 0
  }
  isError = false
  isReady = false
  errorMessage = this.$t('c:payment:Unable to load payment details, contact support if you are not expecting this')

  created() {
    i18n.setLanguage(this.lang)
    this.getInitialData()
  }

  getInitialData() {
    this.$axios.get(`/v3/order/payment/get_payment_info?h=${this.h}&s=${this.s}&o=${this.o}`)
      .then(response => {
        if (!response || !response?.data?.data) {
          this.isError = true
        } else {
          this.isReady = true
          this.data = response.data.data
        }
      })
      .catch(error => {
        this.isError = true
        if (error?.response?.data?.errors.length) {
          if (error.response.data.errors[0].type !== 'generic') {
            this.errorMessage = error.response.data.errors[0].message
          }
        }
      })
  }

  choosePaymentMethod() {
    window.location.href = this.data.checkoutUrl
  }
}
